var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column flex-lg-row gap-5"},[_c('div',{staticClass:"col-12 col-lg-4"},[_c('div',{staticClass:"card h-lg-100"},[_c('div',{staticClass:"card-header pt-5 border-0"},[_c('div',{staticClass:"card-title d-flex flex-column"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2"},[_vm._v(_vm._s(_vm._f("formatMoney")(_vm.totalAmountSold / 100)))])]),_c('span',{staticClass:"text-gray-400 pt-1 fw-semibold fs-6"},[_vm._v("Valor Total Vendido")])])]),_c('div',{staticClass:"card-body d-flex pt-2 pb-4"},[_c('DoughnutChart',{attrs:{"chart-options":{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'right'
              }
            }
          },"chart-data":_vm.chartDataTotalSold,"chart-id":"doughnut-chart","dataset-id-key":"label","height":100,"width":300}})],1)])]),_c('div',{staticClass:"col-12 col-lg-4"},[_c('div',{staticClass:"card h-lg-100"},[_c('div',{staticClass:"card-header pt-5 border-0 align-items-center justify-content-start gap-5"},[_c('div',{staticClass:"m-0"},[_c('span',{staticClass:"svg-icon svg-icon-2hx svg-icon-gray-600"},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"opacity":"0.3","d":"M14 3V21H10V3C10 2.4 10.4 2 11 2H13C13.6 2 14 2.4 14 3ZM7 14H5C4.4 14 4 14.4 4 15V21H8V15C8 14.4 7.6 14 7 14Z","fill":"currentColor"}}),_c('path',{attrs:{"d":"M21 20H20V8C20 7.4 19.6 7 19 7H17C16.4 7 16 7.4 16 8V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z","fill":"currentColor"}})])])]),_c('div',{staticClass:"d-flex flex-column my-3"},[_c('span',{staticClass:"fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2"},[_vm._v(_vm._s(_vm._f("formatMoney")(_vm.data.total_paid / 100)))]),_vm._m(0)])]),_c('div',{staticClass:"card-body d-flex pt-2 pb-4"},[_c('DoughnutChart',{attrs:{"chart-options":{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'right'
              }
            }
          },"chart-data":_vm.chartDataTotalPaid,"chart-id":"doughnut-chart","dataset-id-key":"label","height":100,"width":300}})],1)])]),_c('div',{staticClass:"col-12 col-lg-3"},[_c('div',{staticClass:"card h-lg-100"},[_c('div',{staticClass:"card-body d-flex justify-content-between align-items-start flex-column"},[_c('div',{staticClass:"m-0"},[_c('span',{staticClass:"svg-icon svg-icon-2hx svg-icon-gray-600"},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"opacity":"0.3","d":"M14 3V21H10V3C10 2.4 10.4 2 11 2H13C13.6 2 14 2.4 14 3ZM7 14H5C4.4 14 4 14.4 4 15V21H8V15C8 14.4 7.6 14 7 14Z","fill":"currentColor"}}),_c('path',{attrs:{"d":"M21 20H20V8C20 7.4 19.6 7 19 7H17C16.4 7 16 7.4 16 8V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z","fill":"currentColor"}})])])]),_c('div',{staticClass:"d-flex flex-column my-7"},[_c('span',{staticClass:"fw-semibold fs-3x text-gray-800 lh-1 ls-n2"},[_vm._v(_vm._s(_vm._f("formatMoney")(_vm.data.commission / 100)))]),_vm._m(1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-0"},[_c('span',{staticClass:"fw-semibold fs-6 text-gray-400"},[_vm._v("Valor Total Recebido pela Plataforma")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-0"},[_c('span',{staticClass:"fw-semibold fs-6 text-gray-400"},[_vm._v("Comissão a receber")])])
}]

export { render, staticRenderFns }